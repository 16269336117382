<script>
import Tabulation from "@/view/tabulate/Tabulate.vue";
import Md5 from "js-md5";

export default {
  components: {Tabulation},
  data(){
    return{
      tableData: null,
      pageSize: 20,
      pageNum: 1,
      total: 0,
    }
  },
  created() {
    this.getTableData()
  },
  computed: {
    tabData(){
      let obj = {
        columnName: [
          {name: '用户姓名', prop: 'userName', type: 'text', needSort: false},
          {name: '登录账号', prop: 'account', type: 'text', needSort: false},
          {name: '电话', prop: 'phoneNumber', type: 'text', needSort: false},
          {name: '微信openid', prop: 'openId', type: 'text', needSort: false},
          {name: '用户类型', prop: 'userLevel', type: 'text', needSort: false, format: 'level'},
          {name: '状态', prop: 'userStatus', type: 'switch', needSort: false, format: 'num'},
          {name: '创建时间', prop: 'createTime', type: 'text', needSort: false},
          {name: '更新时间', prop: 'updateTime', type: 'text', needSort: false},
        ],
        rows: this.tableData,
        control: [
          {name: '修改', type: 'warning', method: this.updateDict},
          {name: '重置密码', type: 'danger', method: this.resetting},
        ]
      }
      if(this.$store.state.user.account !== "admin"){
        obj.control.splice(1,1)
      }
      return obj
    }
  },
  methods:{
    /**
     * 获取字典数据
     */
    getTableData(){
      this.axios.get(this.$api.publicUrl.UserList, {
        params: {
          pageSize: this.pageSize,
          pageNum: this.pageNum
        }
      }).then(res =>{
        if(res.data.status === 200){
          this.tableData = res.data.data.list
          this.total = res.data.data.total
        }else {
          this.$message.error(res.data.message)
        }
      })
    },
    /**
     * 1、创建模板数据； 2、调用创建弹出层的方法
     * @param ti  {string}  标题
     * @param mod {string}  方式；例如 添加、修改
     * @param data  {Object}  源数据； 最终弹出层的回调事件会将修改后的源数据返回，以便于后续提交表单数据
     */
    createTemplateData(ti, mod, data = null){
      this.$createPopup("form",{
        ti: ti,
        tree: [
          [{label: "姓名", type: "text", key: "userName", prop: "userName"},
            {label: "电话", type: "number", key: "phoneNumber", prop: "phoneNumber"}],
        ],
        formData: data || {
          userName: "",
          phone: "",
        },
        rules: {
          userName: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
          phoneNumber: [{ required: true, message: '请输入电话', trigger: 'blur' }],
        }
      }, mod, this.poPupCallback)
    },
    /**
     * 表格的回调事件，主要用于每一行的状态修改
     * @param data {Object} 数据
     * @param fun {function}  回调事件， 如果修改未成功，可以调用此方法对已修改的数据回退
     */
    updateTabStatus(data, fun){
      this.axios.put(this.$api.publicUrl.UpdateUserStatus, data).then(res =>{
        if(res.data.status === 200){
          this.$message.success("修改成功")
        }else {
          this.$message.error(res.data.message)
          fun("userStatus")
        }
      })
    },
    /**
     * 新增
     */
    addDict(){
      this.createTemplateData("新增用户", "add")
    },
    /**
     * 修改
     */
    updateDict(data){
      this.createTemplateData("修改用户","update", data)
    },
    /**
     * 重置密码
     */
    resetting(){
      this.$confirm('此操作将重置此用户密码, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.delPassword()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消重置'
        });
      });
    },
    delPassword(){
      this.axios.put(this.$api.publicUrl.UpdatePassWord, Md5("123456"), {
        headers: {
          'Content-Type': 'text/plain'
        }
      }).then(res =>{
        if(res.data.status === 200){
          this.$message.success("密码重置成功")
        }else {
          this.$message.error("密码重置失败")
        }
      })
    },
    /**
     * 删除
     */
    delDict(id){
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.sendService(id, "del")
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });

    },
    /**
     * 弹出层回调事件
     * 这里可能需要针对某些接口做数据预处理， 最后将数据发送给服务器
     * @param data
     * @param mode
     */
    poPupCallback(data, mode){
      this.sendService(data, mode)
    },
    /**
     * 向服务器发送请求，以及回调的状态处理
     * @param data
     * @param mode
     */
    sendService(data, mode){
      new Promise((resolve, reject)=>{
        switch (mode){
          case "add":
            this.axios.post(this.$api.publicUrl.UserList, data).then(res =>{
              if(res.data.status === 200){
                resolve("添加成功!")
              }else {
                reject(res.data.message)
              }
            })
            break
          case "update":
            this.axios.put(this.$api.publicUrl.UpdateUserInfo, data).then(res =>{
              if(res.data.status === 200){
                resolve("修改成功!")
              }else {
                reject(res.data.message)
              }
            })
            break
          case "del":
            this.axios.delete(this.$api.publicUrl.UserList + "/" + data ).then(res =>{
              if(res.data.status === 200){
                resolve("删除成功!")
              }else {
                reject(res.data.message)
              }
            })
            break
        }
      }).then(res =>{
        this.getTableData()
        this.$message({
          message: res,
          type: 'success'
        })
      }).catch(err =>{
        this.$message.error(err);
      })
    },

    handleSizeChange(val) {
      this.pageSize = val
      this.getTableData()
    },
    handleCurrentChange(val) {
      this.pageNum = val
      this.getTableData()
    }
  }
}
</script>

<template>
  <fragment v-if="tableData">
    <section class="control">
      <div class="con_ti">总用户数<span>{{this.total}}</span></div>
    </section>
    <section class="tabulation" v-if="tableData">
      <Tabulation :data="tabData" @updateStatus="updateTabStatus"></Tabulation>
    </section>
    <section class="pagination">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="this.pageNum"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="this.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="this.total">
      </el-pagination>
    </section>
  </fragment>
  <fragment v-else>
    <img src="../../../assets/images/loading.gif" class="loading" height="100" width="100" alt="加载中"/>
  </fragment>
</template>

<style src="@/assets/css/componentStyle.css" scoped></style>
